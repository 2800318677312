/*=========================================================================================
    File Name: vertical-overlay-menu.scss
    Description: A overlay style vertical menu with show and hide support. It support
    light & dark version, filpped layout, right side icons, native scroll and borders menu
    item seperation.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

@import "../../../bootstrap-extended/include"; // Bootstrap includes
@import "../../../components/include"; // Components includes

// Import first main menu mixin
@import "../../mixins/main-menu-mixin";

// Vertical Overlay Menu
//=========================
.vertical-overlay-menu {
  //Content expanded
  .content {
    @include main-menu-width(0);
  }
  //Navbar expanded
  .navbar {
    .navbar-header {
      @include menu-navbar-width($menu-expanded-width);
    }
  }
  // Main menu expanded
  .main-menu {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    transition: width 0.25s, opacity 0.25s, transform 0.25s;
    width: $menu-expanded-width;
    left: -($menu-expanded-width);

    .navigation {
      > li {
        > a {
          > svg,
          > i {
            margin-right: 14px;
            float: left;
            transition: 200ms ease all;
            height: 25px;
            width: 25px;
            &:before {
              transition: 200ms ease all;
              font-size: 1.429rem;
            }
          }
        }
      }
      li {
        @include vertical-menu-has-sub-arrow(1rem);
      }

      .navigation-header {
        .feather-more-horizontal {
          display: none;
        }
      }
    }
  }
  &.menu-open {
    .main-menu {
      opacity: 1;
      transform: translate3d($menu-expanded-width, 0, 0);
      transition: width 0.25s, opacity 0.25s, transform 0.25s;
    }
  }
  &.menu-hide {
    .main-menu {
      @extend .main-menu;
    }
  }
}
